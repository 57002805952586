<template>
  <section class="fdb-block" style="background-image: url(imgs/hero/blue.svg);">
    <div class="col-md-5 mx-auto">
      <div class="card rounded shadow-lg">
        <div class="card-body">
          <div class="text-center">
            <img src="images/siged2.png" alt="homepage" class="dark-logo" />
          </div>
          <div class="card-title text-center">
            <h2>{{ title }}</h2>
          </div>
          <form > 
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-12 col-form-label float-right">Se le ha enviado un mensaje a <b>{{ item.email }}</b>.</label>
                  <label class="col-md-12 col-form-label float-right">Antes de ingresar a Siged por primera vez, por favor valide su correo electrónico en su bandeja de entrada.</label>
                  <label class="col-md-12 col-form-label float-right">Si no encuentra el mensaje en la bandeja de entrada, revise la carpeta de correo no deseado o spam.</label>
                </div>
              </div>
            </div>    
            <div class="row" style="text-align:center" >            
              <div class="col-md-12 float-center">
                <router-link :to="{ name: 'Signin' }" class="btn btn-primary float-center">Aceptar</router-link>
              </div>            
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template> 
<script>
  //import toastr from 'toastr';

  export default {
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        buttontext: '',
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {
        this.title = 'Registro exitoso';
        this.buttontext = 'Aceptar';
        this.item.email = this.$route.params.email;

        // FACEBOOK PIXEL
        if (window.location.origin == 'https://app.siged.co'){
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '2655665448019973');
          fbq('track', 'signup');
        }
        // END FACEBOOK PIXEL        
    },
    methods: {
      setClose(close){
        this.close = close;
      }
    }
  }
</script>
<style>
    .warning {
        color: #ff0000;
    }
</style>
